@import "./assets/styles/index";
@import '@picocss/pico/scss/pico';
// override pico styles after importing

[data-theme="dark"] {
  --color: #FFF;
  --primary: #F04739;
  --primary-hover: #f44336;
  --primary-focus: rgba(229, 57, 53, 0.25);
  --primary-inverse: #FFF;
  --background-color: black;
  --h4-color: #FFF;
  --h5-color: #979797;
  --h6-color: #979797;
  --form-element-background-color: #232323;
  --form-element-border-color: '';
  --form-element-color: #FFF;
  --form-element-placeholder-color: #979797;
  --form-element-focus-color: unset;
  --form-element-active-border-color: unset;
  --form-element-invalid-border-color: unset;
  --form-element-invalid-active-border-color: unset;
  --form-element-invalid-focus-color: unset;
  --form-element-valid-border-color: unset;
  --form-element-valid-active-border-color: unset;
  --form-element-valid-focus-color: unset;

  h1, h2, h3, h4, h5, p, span {
    margin: 0
  }

  input[aria-invalid=true], input[aria-invalid=false], select[aria-invalid=true], select[aria-invalid=false] {
    background-image: unset
  }
}

@media (min-width: 350px) {
  .container {
    max-width: 1440px;
  }
}
@media (max-width: 1440px) {
  .container {
    padding: 0 10px;

    img{
      margin-left: 30px;
    }
  }
}


