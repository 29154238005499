[data-container='player'] {
  h5 {
    margin-bottom: 25px;
  }

  li {
    display: flex;
    text-align: left;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
}

.audio-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  > div:nth-of-type(1) {
    display: flex;
    width: 100%;

    > div:nth-of-type(1) {
      display: flex;
     justify-content: space-between;
     align-items: center;

      p {
        width: 60px;
        height: 60px;
        cursor: pointer;
      }
    }

    > div:nth-of-type(2) {
      padding-left: 20px;
      > div {
        display: flex;
        gap: 5px;

        p {
          color: #666666;
          font-size: 12px;
          display: flex;

          > span {
            color: #ffffff;
            width: 34px;
            text-align: center;
          }
        }
      }
    }
  }

  .volume-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    p {
      height: 20px;
      width: 20px;
    }

    input[type='range'] {
      margin: auto;
      -webkit-appearance: none;
      position: relative;
      overflow: hidden;
      height: 10px;
      width: 150px;
      cursor: pointer;
    }

    input[type='range']::-webkit-slider-runnable-track {
      background: #666666;
      border: none;
      height: 10px;
    }

    input[type='range']::-webkit-slider-thumb {
      width: 0;
      height: 0;
      box-shadow: -100vw 0 0 100vw #ffffff;
    }
  }
}

.wave-surfer-body {
  background-color: #232323;
}
@media (max-width: 1440px) {
  .audio-title{
    padding-left: 30px;
  }
  .volume-bar{
    padding-right: 30px;
  }
}
@media (max-width: 1360px) {
  .wave-surfer-body {
    > div:nth-of-type(1) {
      padding-left: 0;
    }
  }
}

@media (max-width: 585px) {
  .audio-title {
    position: relative;

    > div:nth-of-type(1) {
      flex-direction: column;

      > div:nth-of-type(1) {
        margin-bottom: 10px;
      }
      >div:nth-of-type(2){
        padding-left: 0;
      }
    }

    .volume-bar {
      display: none;
    }
  }
}

[data-container='invalid'] {
  max-width: 600px;
  height: 334px;
  margin: 150px auto 0;
  border-radius: 6px;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  div:nth-of-type(2) {
    font-size: 32px;
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
  }

  div:nth-of-type(3) {
    font-size: 15px;
    margin-top: 10px;
    color: #979797;
    text-align: center;
  }
  img {
    margin: 0;
  }
}
